import { Dropdown, MenuProps, Spin } from 'antd';
import { getDownloadURL, ref } from 'firebase/storage';
import { useContext, useEffect, useState } from 'react';
import { AppContext, InitialState } from '../../auth';
import GooggleIcon from '../../images/goggleicon.png';
import { logout, storage } from '../../utils/firebase/firebaseIndex';
import styles from './ProfileInfo.module.scss';
import profileIcon from '../../images/profileicon.png';
import { useNavigate } from 'react-router-dom';
import {
  DownOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  SwapOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { handleOnKeyDown } from '../../utils/eventListeners';
import { InstituteFeatureFlags } from '../../constants/instituteFeatureToggles';

const ProfileInfo = () => {
  const { userInfo, user, signUpOrSignInWithGoogleCall, institute, isMobile } =
    useContext(AppContext) as InitialState;

  const [isPhotoLoading, setIsPhotoLoading] = useState(false);
  const [profilePic, setProfilePic] = useState('');
  const isInstituteCoach = Object.keys(userInfo.instituteRoles || {})?.[0];

  const { t } = useTranslation();

  const navigate = useNavigate();

  const getDownloadUrl = (profilePath: string) => {
    if (profilePath !== '' || profilePath !== null) {
      setTimeout(() => {
        const storageRef = ref(storage, profilePath);
        getDownloadURL(storageRef)
          .then((url: any) => {
            setProfilePic(url);
          })
          .catch((err) => {
            console.log('err', err);
          });
      }, 1000);
    }
  };

  useEffect(() => {
    if (userInfo.profilePic && userInfo.profilePic !== '' && !isPhotoLoading) {
      setIsPhotoLoading(true);
      getDownloadUrl(userInfo.profilePic);
    }
  }, [userInfo, isPhotoLoading]);

  let items: MenuProps['items'] = [
    {
      label: (
        <div className={styles.menuItem}>
          <div className={styles.menuIconAi}>
            <UserOutlined style={{ fontWeight: 'bold' }} />
          </div>
          <div className="button__text">{t('MyProfile')}</div>
        </div>
      ),
      key: 'profile',
      onClick: () => navigate('/profile'),
    },
    {
      label: (
        <div className={styles.menuItem}>
          <div className={styles.menuIconAi}>
            <QuestionCircleOutlined />
          </div>
          <div className="button__text">{t('HelpCenter')}</div>
        </div>
      ),
      key: 'helpcenter',
      onClick: () => {
        window.open('https://help.careerflow.ai', '_blank');
      },
    },
    {
      label: (
        <div className={styles.menuItem}>
          <div className={styles.menuIconAi}>
            <SettingOutlined />
          </div>
          <div className="button__text">{t('Setting')}</div>
        </div>
      ),
      key: 'settings',
      onClick: () => navigate('/settings'),
    },
    {
      label: (
        <div className={styles.menuItem}>
          <div className={styles.menuIconAi}>
            <LogoutOutlined />
          </div>
          <div className="button__text">{t('Logout')}</div>
        </div>
      ),
      key: 'logout',
      onClick: () => {
        logout();
        window?.chrome?.runtime?.sendMessage(
          process.env.VITE_APP_EXTENSION_ID,
          {
            logout: true,
          }
        );
      },
    },
  ];

  const coachViewItem = {
    label: (
      <div className={styles.menuItem}>
        <div className={styles.menuIconAi}>
          <SwapOutlined />
        </div>
        <div className="button__text">{t('SwitchToCoachView')}</div>
      </div>
    ),
    key: 'coach',
    // Redirect to the coach view
    onClick: () => {
      window.location.href =
        'https://coach.careerflow.ai/' +
        isInstituteCoach +
        '?ref=jobseeker_view';
    },
  };

  if (isInstituteCoach) {
    items.splice(1, 0, coachViewItem);
  }

  if (
    institute?.disabledFeatures?.includes(InstituteFeatureFlags['help-center'])
  ) {
    items = items.filter((item) => item.key !== 'helpcenter');
  }

  return (
    <div>
      {!!user && userInfo?.username ? (
        <Dropdown
          trigger={['click']}
          menu={{ items: items }}
          placement="bottomRight"
        >
          <div style={{ padding: '8px', cursor: 'pointer' }}>
            <div
              className={styles.profileDiv}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {!isMobile ? (
                <span>{`${userInfo?.fname || 'My Profile'} ${userInfo?.lname}`}</span>
              ) : null}

              {profilePic ? (
                <img
                  src={profilePic}
                  alt="profile"
                  height="25"
                  width="25"
                  className={styles.avatar}
                  style={isMobile ? { marginLeft: '0px' } : {}}
                />
              ) : (
                <img
                  src={profileIcon}
                  alt="profile icon"
                  height="25"
                  width="25"
                  className={styles.avatar}
                  style={
                    isMobile
                      ? {
                          background: 'white',
                          border: 'none',
                          marginLeft: '0px',
                        }
                      : { background: 'white', border: 'none' }
                  }
                />
              )}
              <DownOutlined
                size={12}
                style={{ color: '#96A0B5', marginLeft: '6px' }}
              />
            </div>
            <div></div>
          </div>
        </Dropdown>
      ) : profilePic === '' && !!user ? (
        <Spin style={{ marginRight: '2rem' }} />
      ) : (
        <div
          style={{ fontSize: '14px' }}
          className="button button--empty"
          onClick={() => signUpOrSignInWithGoogleCall('webapp')}
          onKeyDown={(e) =>
            handleOnKeyDown(e, () => signUpOrSignInWithGoogleCall('webapp'))
          }
          tabIndex={0}
          role="button"
        >
          <img
            className="img-icon"
            src={GooggleIcon}
            alt="google-login"
            style={{
              height: '20px',
              width: '20px',
              marginRight: '2px',
            }}
          />
          <strong>{t('SignIn')}</strong>
        </div>
      )}
    </div>
  );
};

export default ProfileInfo;
