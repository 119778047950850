import {
  AppstoreOutlined,
  BugOutlined,
  BulbOutlined,
  DownOutlined,
  DownloadOutlined,
  ExportOutlined,
  FileDoneOutlined,
  FileTextOutlined,
  FlagOutlined,
  FolderOutlined,
  HomeOutlined,
  LinkedinOutlined,
  SearchOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { analyticsEvent } from '@careerflow/common-components/dist/utils/Analytics';
import { Button, Col, Divider, Menu, Modal, Row, Tooltip } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext, InitialState } from '../../auth';
import businessImg from '../../images/business.png';
import ThemeColors from '../../theme/themeColors';
import DiscordIcon from './DiscordIcon';
import TooltipParagraph from './TooltipParagraph';
import styles from './styles.module.scss';
import { handleOnKeyDown } from '../../utils/eventListeners';
import { InstituteFeatureFlags } from '../../constants/instituteFeatureToggles';
import { INVITE_STATES } from '../Navbar/Navbar';
import { DISCORD_INVITE_LINK, HIRING_SEARCH_LINK } from '../../utils/constants';
import instituteAppService from '../../services/InstituteService';
import { ReactComponent as Vectorjobs } from '../../images/Vectorjobs.svg';

const SideNav = ({
  setIsMenuFolded,
}: {
  setIsMenuFolded?: (value: boolean) => void;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    userInfo,
    appConfig,
    user,
    isExtensionInstalled,
    institute,
    isInstituteCoach,
    selectedInstitute,
    whiteLabeledInstitute,
  } = useContext(AppContext) as InitialState;

  const [selectedItem, setSelectedItem] = React.useState('/');
  const [discordIconColor, setDiscordIconColor] = React.useState('#293056');
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const [isSwitchInstitute, setISwitchInstitute] = React.useState(false);
  const [switchInstitute, setSwitchInstitute] = React.useState<string>('');
  const [whiteLabelUrl, setWhiteLabelUrl] = React.useState<string>('');

  const { t } = useTranslation();

  const onInstituteSwitch = (institute, redirect: boolean = false) => {
    setISwitchInstitute(false);
    if (!institute) return;
    // If user switch to whiteLabel institute and wants to redirect to whiteLabel URL
    if (redirect && whiteLabelUrl) {
      let redirectUrl = whiteLabelUrl;
      if (!/^https?:\/\//i.test(redirectUrl)) {
        redirectUrl = 'https://' + redirectUrl; // Add 'https://' if not present
      }
      window.open(redirectUrl, '_blank');
      return;
    }

    // if user switch to not whiteLabel institute and don't want to redirect to whiteLabel URL
    localStorage.setItem('selectedInstitute', institute);
    window.location.reload();
  };

  const validateInstituteWhiteLabel = async (instituteId) => {
    if (!instituteId) return; // If instituteId is not provided, return
    // Fetch institute details and handle white-label URL redirection
    const res = await instituteAppService.getInstituteById(instituteId);
    setSwitchInstitute(instituteId);
    if (
      typeof res?.data?.whiteLabel === 'string' &&
      window.location.host !== res?.data?.whiteLabel
    ) {
      setWhiteLabelUrl(res?.data?.whiteLabel);
      setISwitchInstitute(true);
    } else {
      setISwitchInstitute(false);
      setWhiteLabelUrl('');
      onInstituteSwitch(instituteId, false);
    }
  };

  const [defaultItems] = React.useState([
    {
      key: '/dashboard',
      label: t('Dashboard'),
      icon: <HomeOutlined style={{ fontSize: '20px' }} />,
    },
    {
      key: '/board',
      label: t('JobBoard'),
      icon: <AppstoreOutlined style={{ fontSize: '20px' }} />,
    },
    {
      key: '/contact',
      label: t('Contacts'),
      icon: <UserOutlined style={{ fontSize: '20px' }} />,
    },
    {
      key: '/documents',
      label: t('Documents'),
      icon: <FileTextOutlined style={{ fontSize: '20px' }} />,
    },
    {
      key: '/linkedin',
      label: t('Linkedin'),
      icon: <LinkedinOutlined style={{ fontSize: '20px' }} />,
    },
    {
      key: '/community',
      label: t('Community'),
      icon: (
        <DiscordIcon color={discordIconColor} style={{ fontSize: '20px' }} />
      ),
      onMouseEnter: () => setDiscordIconColor('#4285f4'),
      onMouseLeave: () => setDiscordIconColor('#293056'),
    },
    {
      key: '/hiring-search',
      label: t('FindRecruiters'),
      icon: <SearchOutlined style={{ fontSize: '20px' }} />,
    },
  ]);

  const [items, setItems] = React.useState<any>(defaultItems);

  useEffect(() => {
    const parts = window.location.pathname.split('/').slice(1);
    const pathParam = parts.join('/');
    setSelectedItem(`/${pathParam}`);
  }, [location]);

  const studentMenu = [
    {
      key: `/organization/overview`,
      label: 'Overview',
      icon: <AppstoreOutlined style={{ fontSize: '20px' }} />,
    },
    {
      key: `/organization/jobs`,
      label: t('Jobs'),
      hidden: institute?.disabledFeatures?.includes('institute-jobs'),
      icon: <Vectorjobs />,
    },
    {
      key: `/organization/resources`,
      label: t('Resources'),
      hidden: institute?.disabledFeatures?.includes('institute-resources'),
      icon: <FolderOutlined style={{ fontSize: '20px' }} />,
    },
    {
      key: `/organization/goals`,
      label: t('Goals'),
      icon: <FlagOutlined style={{ fontSize: '20px' }} alt="flag" />,
      hidden: !institute?.studentGoals,
      style: {
        color:
          selectedItem === '/goals'
            ? ThemeColors.themeBackgroundColor
            : undefined,
      },
    },
  ];

  useEffect(() => {
    const originalItems = [...defaultItems].filter(
      (item) => !institute?.disabledFeatures?.includes(item?.key?.split('/')[1])
    );

    if (
      (appConfig?.features?.resumebuilder?.enabled === true ||
        appConfig?.features?.resumebuilder?.betaUsers.includes(user.email)) &&
      !institute?.disabledFeatures?.includes('resume-builder')
    ) {
      originalItems.splice(2, 0, {
        key: '/resumebuilder',
        label: 'Resume Builder',
        icon: <FileDoneOutlined style={{ fontSize: '20px' }} />,
      });
    }
    setItems(originalItems);
  }, [
    appConfig?.features?.resumebuilder?.betaUsers,
    appConfig?.features?.resumebuilder?.enabled,
    appConfig?.features?.resumebuilder.status,
    navigate,
    user,
    user.email,
    defaultItems,
    institute?.disabledFeatures,
  ]);

  const instituteSwitchItems = Object.keys({
    ...userInfo?.invites,
    ...userInfo?.instituteRoles,
  })
    .filter((key) => userInfo?.invites?.[key] !== INVITE_STATES.deactivated)
    .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        fontSize: '14px',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        borderRight: '1px solid #e1edff',
        color: '#98A2B3',
        overflowY: 'auto',
      }}
    >
      <Modal
        title={'Switch Institute'}
        open={isSwitchInstitute}
        onCancel={() => onInstituteSwitch(switchInstitute, false)}
        onOk={() => onInstituteSwitch(switchInstitute, true)}
        footer={
          <div className="flex-center">
            <Button
              key="cancel"
              size="middle"
              className=""
              onClick={() => onInstituteSwitch(switchInstitute, false)}
            >
              Stay on this Site
            </Button>
            <Button
              size="middle"
              onClick={() => onInstituteSwitch(switchInstitute, true)}
            >
              Switch to Organization Portal <ExportOutlined />
            </Button>
          </div>
        }
      >
        <div style={{ padding: '1rem' }}>
          {`You are about to switch to a different institute. Do you want to continue on this website or switch to the institute's `}
          <span style={{ fontWeight: 'bold' }}>{whiteLabelUrl}</span>
          {` website?`}
        </div>
      </Modal>

      <div>
        <Menu
          mode="inline"
          onClick={(e) => {
            if (setIsMenuFolded) {
              setIsMenuFolded(true);
            }
            if (e.key === '/community') {
              if (institute?.communityUrl) {
                window.open(institute?.communityUrl, '_blank');
              } else {
                window.open(DISCORD_INVITE_LINK, '_blank');
              }
            }
            if (e.key === '/hiring-search') {
              window.open(HIRING_SEARCH_LINK, '_blank');
            } else {
              navigate(`${e.key}`);
              analyticsEvent('Navigate ' + e.key, 'Side Nav', [
                {
                  route: e.key,
                  source: 'Side Nav',
                },
              ]);
              setSelectedItem(e.key);
            }
          }}
          style={{
            borderRight: 0,
            fontSize: '14px',
            color: '#293056',
            width: '95%',
            margin: 'auto',
          }}
          defaultSelectedKeys={['/']}
          selectedKeys={[selectedItem]}
        >
          {items
            .filter((item) => !item.hidden)
            .map((item: any) => (
              <Menu.Item key={item.key} style={{ paddingLeft: 12 }}>
                {item.key === '/resumebuilder' && (
                  <div
                    style={{
                      position: 'absolute',
                      zIndex: '1',
                      right: '5px',
                      color: 'white',
                      lineHeight: 'normal',
                      fontSize: 'smaller',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        background: '#e6f4ff',
                        borderRadius: '1rem',
                        padding: '0.25rem 0.5rem',
                        color: '#293056',
                      }}
                    >
                      New
                    </div>
                  </div>
                )}

                <TooltipParagraph
                  icon={item.icon}
                  style={{
                    fontSize: '14px',
                    color: '#293056',
                    width: '70%',
                    margin: 'auto',
                    lineHeight: '40px',
                    marginLeft: '10px',
                    opacity: '1',
                  }}
                >
                  {item.label}
                </TooltipParagraph>
              </Menu.Item>
            ))}
        </Menu>
      </div>

      {isInstituteCoach ||
      userInfo?.invites?.[selectedInstitute] === 'ACCEPTED' ? (
        <>
          <div style={{ borderTop: '1px solid #E1EDFF', margin: '1rem' }}></div>
          <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
            <Col span={5} style={{ textAlign: 'right' }}>
              <img
                src={institute?.logoUrl ?? ''}
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.src = businessImg;
                  target.onerror = null;
                }}
                height={25}
                alt="institute logo"
              />
            </Col>
            <Col
              span={14}
              style={{
                marginLeft: '8px',
                color: '#293056',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {institute?.name}
            </Col>

            {!whiteLabeledInstitute && instituteSwitchItems.length > 1 && (
              <Col span={3} style={{ color: '#293056' }}>
                <Tooltip title="Switch Organization">
                  <DownOutlined onClick={() => setOpenDropdown(true)} />
                  <div onMouseLeave={() => setOpenDropdown(false)}>
                    {openDropdown && (
                      <div
                        style={{
                          position: 'fixed',
                          borderRadius: '10px',
                          padding: '0.5rem 1rem',
                          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                          background: 'white',
                          zIndex: 999,
                          maxHeight: '25rem',
                        }}
                      >
                        <div
                          style={{
                            paddingBottom: '0.5rem',
                            fontWeight: '600',
                            textAlign: 'center',
                          }}
                        >
                          Switch Organization
                        </div>
                        <Divider style={{ padding: '0', margin: '0' }} />
                        <div style={{ maxHeight: '20rem', overflowX: 'auto' }}>
                          {instituteSwitchItems.map((institute) => (
                            <div
                              className={styles.menuButton}
                              role="button"
                              tabIndex={0}
                              key={institute}
                              onKeyDown={(e) => {
                                handleOnKeyDown(e, () => {
                                  validateInstituteWhiteLabel(institute);
                                });
                              }}
                              onClick={() => {
                                validateInstituteWhiteLabel(institute);
                              }}
                            >
                              {institute}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </Tooltip>
              </Col>
            )}
          </Row>
          <Menu
            mode="inline"
            onClick={(e) => {
              navigate(`${e.key}`);
              analyticsEvent('Navigate ' + e.key, 'Side Nav');
            }}
            style={{
              borderRight: 0,
              fontSize: '14px',
              color: '#293056',
              width: '95%',
              margin: 'auto',
              marginTop: '10px',
            }}
            // defaultSelectedKeys={['/']}
            selectedKeys={[selectedItem]}
            items={studentMenu}
          />
        </>
      ) : null}

      <div
        style={{
          marginTop: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'center',
          gap: '0.75rem',
          paddingBottom: '1rem',
        }}
      >
        {!isExtensionInstalled &&
          !institute?.disabledFeatures?.includes(
            InstituteFeatureFlags['download-extension-button']
          ) && (
            <Button
              className="tertiary-btn"
              onClick={() =>
                window.open(
                  'https://chrome.google.com/webstore/detail/careerflows-linkedin-opti/iadokddofjgcgjpjlfhngclhpmaelnli'
                )
              }
            >
              <DownloadOutlined /> {t('DownloadExtension')}
            </Button>
          )}
        {!institute?.id && (
          <Button
            onClick={() => {
              let feedbackUrl = 'https://forms.gle/LetbiAiBv5m18SzHA';
              if (appConfig.features?.canny_suggest?.status === 'enabled')
                feedbackUrl = 'https://careerflow.canny.io/feature-suggestions';
              window.open(feedbackUrl);
            }}
          >
            <BulbOutlined /> {t('SuggestaFeature')}
          </Button>
        )}
        {!institute?.disabledFeatures?.includes(
          InstituteFeatureFlags.intercom
        ) && (
          <Button className="reportAnIssue">
            <BugOutlined />
            {t('leftPanel.ReportanIssue')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default SideNav;
