import { Button, Modal } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import logo from '../../images/logo.png';
import premiumStar from '../../images/premiumStar.svg';
import whiteStars from '../../images/white-stars.svg';
import { analyticsEvent } from '@careerflow/common-components/dist/utils/Analytics';
import styles from './index.module.scss';
import { useContext } from 'react';
import { AppContext } from '../../auth';
import aiResumeBuilder from '../../images/aiResumeBuilder.png';
import linkedinOptimization from '../../images/linkedinOptimization.svg';
import coverLetter from '../../images/coverLetter.svg';
import skillMatch from '../../images/skillMatch.svg';
import jobTracker from '../../images/jobTracker.svg';
import autofill from '../../images/autofill.svg';
import whosHiring from '../../images/whosHiring.svg';
import contactTracking from '../../images/contactTracking.svg';
import postGenerator from '../../images/postGenerator.svg';
import summarizeJD from '../../images/summarizeJD.svg';
import documents from '../../images/documents.svg';
import headlineGenerator from '../../images/headlineGenerator.svg';

const Features = ({ open, setOpen, featureMode, setFeatureMode }: any) => {
  const navigate = useNavigate();
  const { institute, isMobile } = useContext(AppContext);
  const { t } = useTranslation();
  const featureCards = [
    {
      logo: aiResumeBuilder,
      heading: t('AIResumeBuilder'),
      action: () => navigate('/resumebuilder'),
      text: 'Leverage the power of AI to customize your resume for targeted for a jobs you aim for.',
      tag: { color: '#162b5b', text: 'New' },
      isPremium: true,
      hidden: institute?.disabledFeatures?.includes('resume-builder'),
    },
    {
      logo: linkedinOptimization,
      heading: t('LinkedInOptimization'),
      url: 'https://www.linkedin.com/in',
      action: () => window.open('https://www.linkedin.com/in/'),
      text: 'Follow our expert suggestions for your profile and build a star profile.',
      isPremium: false,
      hidden: institute?.disabledFeatures?.includes('linkedin'),
    },
    {
      logo: coverLetter,
      heading: t('CoverLetter'),
      action: () => navigate('/cover-letter'),
      text: 'Get customized AI Cover letter for each job description highlighting your profile.',
      isPremium: true,
    },
    {
      logo: skillMatch,
      heading: t('skillMatching'),
      action: () => window.open('https://www.careerflow.ai/skill-match'),
      text: 'Match your resume against jobs you apply for.',
      isPremium: true,
    },
    {
      logo: jobTracker,
      heading: t('JobTracker'),
      action: () => navigate('/board'),
      text: 'Track your job search journey to stay organized and vigilant about your job search.',
      isPremium: false,
    },
    {
      logo: autofill,
      heading: t('Autofill'),
      text: 'Autofill your job applications with one click.',
      action: () => window.open('https://www.careerflow.ai/autofill'),
      isPremium: false,
    },
    {
      logo: whosHiring,
      heading: t('whoIsHiring'),
      action: () => window.open('https://hiring-search.careerflow.ai/'),
      text: `Search for who's hiring for your dream job and be able to reach out to them.`,
      isPremium: false,
    },
    {
      logo: contactTracking,
      heading: t('contactFeature'),
      action: () => navigate('/contact'),
      text: 'Save your job search contacts at one place.',
      isPremium: false,
    },
    {
      logo: postGenerator,
      heading: t('PostGenerator'),
      text: 'Generate LinkedIn posts using AI and share your skills and expertise with your network.',
      url: '/post-generator',
      action: () => navigate('/post-generator'),
      isPremium: true,
    },
    {
      logo: summarizeJD,
      heading: t('SummarizeJD'),
      action: () => navigate('/'),
      text: 'Save your time reading the whole job description. Let AI read summarize it for you.',
      isPremium: true,
    },
    {
      logo: documents,
      heading: t('documentFeature'),
      action: () => navigate('/documents'),
      text: 'Keep all your documents in one place which you can access anywhere anytime.',
      isPremium: false,
    },
    {
      logo: headlineGenerator,
      heading: t('HeadlineGenerator'),
      url: '/linkedin-headline',
      action: () => navigate('/linkedin-heading'),
      text: 'Create an intriguing and optimized headline using AI.',
      isPremium: true,
      hidden: institute?.disabledFeatures?.includes('linkedin'),
    },
  ];

  // useEffect(() => {
  //   if (userInfo.userType === 'premium') {
  //     navigate('/thanks');
  //   }
  // }, [navigate, userInfo.userType]);

  return (
    <Modal
      onCancel={() => {
        analyticsEvent('Close modal', 'welcome');
        setOpen(false);
      }}
      open={open}
      width={featureMode ? '80%' : isMobile ? '90%' : '60%'}
      style={
        featureMode
          ? {
              top: '4rem',
              height: '80vh',
              borderRadius: '8px',
              padding: '0',
            }
          : {
              borderRadius: '8px',
              top: '12rem',
            }
      }
      title={featureMode ? 'Explore Features' : null}
      className={featureMode ? 'temp' : ''}
      footer={null}
    >
      {!featureMode ? (
        <div className={styles.welcomeRoot}>
          <div>
            <img height={40} src={logo} alt="careerflow logo" />
          </div>
          <div style={{ margin: '0.5rem 0' }} className="heading-5-light">
            <span className="mr-1">👋</span> {t('Welcome')}
          </div>
          <div className="sub-text" style={{ marginBottom: '1rem' }}>
            Let's dive into the world of job search!
          </div>
          <Button
            onClick={() => {
              setOpen(false);
              analyticsEvent(`Let's get started`);
            }}
            size="large"
            className="primary-btn"
          >
            Let's Get Started
          </Button>
          <Button
            onClick={() => {
              analyticsEvent('Explore Careerflow Features');
              setFeatureMode(true);
            }}
            size="large"
            style={{ marginTop: '0.75rem' }}
          >
            Explore Features
          </Button>
        </div>
      ) : (
        <div className={styles.paymentPlansRoot}>
          <div className={styles.recommendedFeatures}>
            <div style={{}} className={'sub-heading'}>
              {t('landJobWithoutStress')}
            </div>

            <div
              style={{ fontSize: '14px', fontWeight: 400, color: '#667085' }}
            >
              {t('discoverFeatures')}
            </div>
            <div className={styles.cardContainer}>
              {featureCards
                .filter((card) => !card?.hidden)
                .map((card: any) => (
                  <div
                    className={
                      styles.recommendationCard +
                      (card.isPremium ? ` ${styles.premiumCard}` : '')
                    }
                  >
                    {card.logo && (
                      <img
                        src={card.logo}
                        alt="recommended app"
                        style={{
                          objectFit: 'contain',
                          marginBottom: '1rem',
                          width: '100%',
                        }}
                      />
                    )}
                    <div
                      className={'sub-heading'}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      {card.heading}
                      {card.tag && (
                        <div
                          style={{
                            color: 'white',
                            backgroundImage:
                              'linear-gradient(45deg, #539CF7 0%, #487CEE 100%)',
                            fontSize: '14px',
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '0.1rem 0.4rem',
                            gap: '0.2rem',
                          }}
                        >
                          <img alt="stars" src={whiteStars} height={12} />
                          {card.tag?.text}
                        </div>
                      )}
                    </div>

                    <Paragraph
                      style={{ minHeight: '2lh', fontSize: '12px' }}
                      ellipsis={{ rows: 2 }}
                    >
                      {card.text}
                    </Paragraph>
                    {!card.isPremium ? (
                      <Button
                        className="primary-btn"
                        onClick={() => {
                          analyticsEvent(`Get Started Feature`, undefined, {
                            feature: card.heading,
                          });
                          card.action();
                          setOpen(false);
                        }}
                      >
                        Get Started
                      </Button>
                    ) : (
                      <Button
                        style={{
                          background: '#152C5B',
                          borderRadius: '6px',
                          display: 'flex',
                          alignItems: 'center',
                          color: 'white',
                          gap: 7,
                        }}
                        onClick={() => {
                          analyticsEvent(`Get Started Feature`, undefined, {
                            feature: card.heading,
                          });
                          card.action();
                          setOpen(false);
                        }}
                      >
                        <img src={premiumStar} alt="star" />
                        Get Started
                      </Button>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default Features;
