import { ThemeConfig } from 'antd';

const globalTheme: ThemeConfig = {
  token: {
    fontFamily: 'Inter',
  },
  components: {
    Button: {
      colorPrimary: 'rgb(21,112,239)',
      colorPrimaryBorder: 'rgb(21,112,239)',
      primaryShadow: '0 2px 0 rgba(5, 145, 255, 0.1)',
      colorPrimaryHover: 'rgb(23,92,211)',
      colorPrimaryActive: 'rgb(24,73,169)',
      defaultBg: 'rgb(255,255,255)',
      defaultBorderColor: 'rgb(228,231,236)',
      defaultShadow: '0px 2px 0px rgba(0, 0, 0, 0.02)',
      defaultHoverBorderColor: 'rgb(21,112,239)',
      defaultActiveBorderColor: 'rgb(23,92,211)',
      textHoverBg: 'rgba(0,0,0,0.06)',
      linkHoverBg: 'rgba(0,0,0,0)',
    },
    Typography: {
      lineHeight: 1.5,
      lineHeightHeading1: 1.21,
      lineHeightHeading2: 1.27,
      lineHeightHeading3: 1.33,
      lineHeightHeading4: 1.4,
      lineHeightHeading5: 1.5,
      fontSizeHeading1: 38,
      fontSizeHeading2: 30,
      fontSizeHeading3: 24,
      fontSizeHeading4: 20,
      fontSizeHeading5: 16,
    },
  },
};

export default globalTheme;
