import axios, { InternalAxiosRequestConfig } from 'axios';
import { jobTrackerApiPath } from '../utils/apiPaths';
import { auth } from '../utils/firebase/firebaseIndex';

export type DefaultJobBoard = {
  version: number;
  id?: string;
};

export const jobTrackerClient = axios.create({
  baseURL: jobTrackerApiPath,
});

jobTrackerClient.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    config.headers['sourceApp'] = `JOB_SEEKER`;
    config.headers['institute'] = localStorage.getItem('selectedInstitute');
    if (config && config.headers && auth.currentUser) {
      const accessToken = await auth.currentUser.getIdToken();
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },

  (error: any) => Promise.reject(error)
);

const getInstituteGoals = (instituteId: string) => {
  return jobTrackerClient.get(`/goals/${instituteId}`);
};

const getJobBoard = async (boardId?: string) => {
  const res = await jobTrackerClient.get(`/board`, {
    params: {
      id: boardId,
    },
  });

  return res.data;
};

const editJob = async (payload: any) => {
  return jobTrackerClient.put('/job', payload);
};

const getInstituteGoalsById = (instituteId: string, goalId: string) => {
  return jobTrackerClient.get(`/goals/${instituteId}`, {
    params: {
      goalId: goalId,
    },
  });
};

const getStudentGoals = (userId: string) => {
  return jobTrackerClient.get(`/goalsProgress`, {
    params: {
      userId,
    },
  });
};

const getGoalJobs = (goalId: string, userId: string) => {
  return jobTrackerClient.get(`/goalsProgress/jobs`, {
    params: {
      goalId,
      userId,
    },
  });
};

const getGoalContacts = (goalId: string, userId: string) => {
  return jobTrackerClient.get(`/goalsProgress/contacts`, {
    params: {
      goalId,
      userId,
    },
  });
};

const postReccuring = (instituteId: string, userId: string) => {
  return jobTrackerClient.post(`/goals/${instituteId}/reccuring`, {
    userId: userId,
  });
};

const getUserDetailById = (userId: string) => {
  return jobTrackerClient.get('/userdetail', {
    params: {
      userId: userId,
    },
  });
};
const updateUserDetailById = (payload: any) => {
  return jobTrackerClient.put('/userdetail', payload);
};

const getJobDetailById = (params: any) => {
  return jobTrackerClient.get('/job/details', {
    params: {
      ...params,
    },
  });
};

const updateJobDetailById = (payload: any) => {
  return jobTrackerClient.put('/job', payload);
};

const updateDocument = (payload: any) => {
  return jobTrackerClient.put('/documents', payload);
};

const getDocumentsById = (userId: string) => {
  return jobTrackerClient.get('/documents', {
    params: {
      userId: userId,
    },
  });
};

const getDocumentByDocId = (userId: string, documentId: string) => {
  return jobTrackerClient.get('/documents', {
    params: {
      userId: userId,
      documentId: documentId,
    },
  });
};

const getDashboardMetadata = () => {
  return jobTrackerClient.get('/dashboard');
};

const rewriteAIDescription = (body: any) => {
  return jobTrackerClient.post('/resume-builder/ai/rewriteExperience', body);
};

const updateBackendStringByFieldName = (
  fieldName: string,
  fieldValue: any,
  resumeIdFromUrl: string
) => {
  return jobTrackerClient.put(
    `/resumes/${resumeIdFromUrl}/${fieldName}`,
    fieldValue,
    {
      headers: {
        'Content-Type': 'text/plain',
      },
    }
  );
};

const updateJobForResumeSkillMatch = (
  postData: any,
  resumeIdFromUrl: string
) => {
  return jobTrackerClient.put(
    `/resumes/${resumeIdFromUrl}/update/job`,
    postData
  );
};

const updateBackendObjectByFieldName = (
  fieldName: string,
  fieldValue: any,
  resumeIdFromUrl: string
) => {
  return jobTrackerClient.put(
    `${jobTrackerApiPath}/resumes/${resumeIdFromUrl}/${fieldName}`,
    { ...fieldValue }
  );
};

const updateDocumentStoreObjectByFieldName = (
  fieldName: string,
  fieldValue: any,
  documentId: string
) => {
  return jobTrackerClient.put(
    `${jobTrackerApiPath}/documents/${documentId}/${fieldName}`,
    { ...fieldValue }
  );
};

const moveJob = (payload) => {
  return jobTrackerClient.put(`${jobTrackerApiPath}/board/moveJob`, {
    ...payload,
  });
};

const updateDocumentStoreStringByFieldName = (
  fieldName: string,
  fieldValue: any,
  documentId: string
) => {
  return jobTrackerClient.put(
    `${jobTrackerApiPath}/documents/${documentId}/${fieldName}`,
    fieldValue,
    {
      headers: {
        'Content-Type': 'text/plain',
      },
    }
  );
};

const contactCreateCustomTag = (tag: string) => {
  return jobTrackerClient.post(`${jobTrackerApiPath}/contact-tags`, {
    tag,
  });
};

const contactGetAllCustomTags = () => {
  return jobTrackerClient.get(`${jobTrackerApiPath}/contact-tags`);
};

const jobTrackerService = {
  getInstituteGoals,
  getInstituteGoalsById,
  getStudentGoals,
  getGoalJobs,
  getGoalContacts,
  postReccuring,
  getUserDetailById,
  getDocumentsById,
  getDocumentByDocId,
  updateUserDetailById,
  updateDocument,
  getJobDetailById,
  updateJobDetailById,
  getDashboardMetadata,
  rewriteAIDescription,
  updateBackendStringByFieldName,
  updateBackendObjectByFieldName,
  updateDocumentStoreObjectByFieldName,
  updateDocumentStoreStringByFieldName,
  updateJobForResumeSkillMatch,
  getJobBoard,
  editJob,
  moveJob,
  contactCreateCustomTag,
  contactGetAllCustomTags,
};

export default jobTrackerService;
