import {
  DownloadOutlined,
  FileTextFilled,
  HistoryOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Collapse,
  Divider,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  message,
} from 'antd';
import axios from 'axios';
import clsx from 'clsx';

import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useIndexedDB } from 'react-indexed-db-hook';
import { AppContext, InitialState } from '../../../auth';
import fileAdd from '../../../images/fileAdd.svg';
import nodocument from '../../../images/noDocuments.png';

import ChromeExtensionService from '../../../services/ChromeExtensionService';
import {
  aiServiceBasePath,
  instituteAppApiPath,
  miscApiPath,
} from '../../../utils/apiPaths';
import { auth } from '../../../utils/firebase/firebaseIndex';

import Loader from '../../Loader';
import PdfParser from '../../PdfParser';
import styles from './HeadlineGenerator.module.scss';

import { analyticsEvent } from '@careerflow/common-components/dist/utils/Analytics';
import { useTranslation } from 'react-i18next';
import AIResults from '../AIResults';
import AIToolHistoryCard from '../CoverLetter/HistoryCard';
import LanguageDropdown from '../LanguageDropdown';

import useAIToolQueryParamSetting from '@careerflow/common-components/dist/customHooks/useAIToolQueryParamSetting';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import aiService from '../../../services/AIService';
import AiDocuments from '../AiDocuments/AiDocuments';
import FollowupInput from '../FollowupInput';
import CoverLetterTemplates from '../../CoverLetterTemplates';
import ImportJobDescriptionModal from '@careerflow/common-components/dist/components/AITools/ImportJobDescription';

const JobOutreachEmail: React.FC<any> = ({ setUpgrade }) => {
  const [resultLoading, setResultLoading] = useState(false);

  const { getAll } = useIndexedDB('resume');
  const [keywords_to_include, setKeyWordsToInclude] = useState('');
  const [historyLoading, setHistoryLoading] = useState(false);
  const [feedbackRecorded, setFeedbackRecorded] = useState<boolean>(false);
  const [aiResponseId, setAiResponseId] = useState();
  const [headlineHistory, setHeadlineHistory] = useState<Array<any>>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [result, setResult] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [showHistory, setShowHistory] = useState(false);
  const [profileSelection, setProfileSelection] = useState('resume');
  const [linkedinProfile, setLinkedinProfile] = useState<any>();
  const [templateChangeMode, setTemplateChangeMode] = useState(false);
  const [user] = useAuthState(auth);
  const [resumeText, setResumeText] = useState('');
  const [additionalContext, setAdditionalContext] = useState('');
  const [resultTone, setResultTone] = useState('');
  const [aboutTheReceiver, setAboutTheReciever] = useState('');
  const [resumeForResult, setResumeForResult] = useState<any>();
  const [resultLength, setResultLength] = useState<any>('medium');
  const [selectedLanguage, setSelectedLanguage] = useState<string>('');
  const [languageChangeMode, setLanguageChangeMode] = useState(false);
  const [followupPrompt, setFollowupPrompt] = useState('');
  const [templates, setTemplates] = useState<Array<any>>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [openImportJD, setOpenImportJD] = useState(false);
  const [jobDescription, setJobDescription] = useState('');
  const [emailType, setEmailType] = useState('job_outreach');

  const { userInfo, institute, appConfig } = React.useContext(
    AppContext
  ) as InitialState;

  const toolname = 'Email Writer';

  const { t } = useTranslation();

  useAIToolQueryParamSetting({
    setSelectedLanguage,
    setJobTitle,
    setKeyWordsToInclude,
  });

  useEffect(() => {
    const getTemplates = async () => {
      if (templates.length) return;
      const response = await axios.get(`${miscApiPath}/email-writer-templates`);

      const freshTemplates = response.data.content || [];

      let instituteTemplates = [];

      const { data } = await axios.get(
        `${instituteAppApiPath}/${institute.id}/email-writer-templates/${emailType}`
      );
      if (data) instituteTemplates = [...data];
      const allTemplates = [...freshTemplates, ...instituteTemplates];
      setTemplates(allTemplates);
      setSelectedTemplate(allTemplates?.[0]?.title);
    };
    getTemplates();
  }, [institute, templates?.length]);

  useEffect(() => {
    if (user)
      ChromeExtensionService.getProfile(user.uid).then((response) => {
        if (!response.data) return;
        const filteredData: any = {
          about: response.data.about,
          education: response.data.education,
          certifications: response.data.extras.licenses_certs,
          name: response.data.extras.name,
          headline: response.data.heading,
          jobs: response.data.jobs,
          location: response.data.location,
          skills: response.data.skills,
        };

        setLinkedinProfile(filteredData);
      });
  }, [user]);

  useEffect(() => {
    getAll().then((resumes) => {
      for (const resume of resumes) {
        if (resume.default) {
          setResumeForResult(resume);
          break;
        }
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showHistory) setHistoryLoading(true);
    axios
      .get(aiServiceBasePath + '/history/email')
      .then(({ data }) => {
        setHeadlineHistory(data);
      })
      .finally(() => setHistoryLoading(false));
  }, [showHistory]);

  const viewDetailHandler = (historyObject: any) => {
    if (historyObject.inputs) {
      setShowHistory(false);
      setFeedbackRecorded(historyObject.feedback);
      setAiResponseId(historyObject.id);

      const result = `<b>Subject:-</b> ${JSON.parse(historyObject.response || {})?.email.subject_line}

${JSON.parse(historyObject?.response || {})?.email?.body}`;
      setResult(result);
    } else {
      message.error(
        'There is a problem while loading history for brand statement.'
      );
    }
  };

  const onGenerate = () => {
    setResultLoading(true);
    analyticsEvent(`Generate ${toolname}`);
    aiService
      .emailWriter({
        type: emailType,
        targetJobTitle: jobTitle,
        previousResult: JSON.stringify(result),
        aboutTheCompany: jobDescription,
        template: templates?.find(
          (template) => selectedTemplate === template.title
        ),
        jobTitle,
        followupPrompt,
        aboutTheReceiver: aboutTheReceiver,
        keywords_to_include: keywords_to_include.split(/\s+|,\s+/g),
        resume: profileSelection === 'resume' ? resumeText : '',
        resumeId: resumeForResult?.id,
        linkedin_profile: profileSelection !== 'resume' ? linkedinProfile : '',
        source: 'Webapp',
        instituteId: institute?.id,
        language: selectedLanguage,
        additionalContext,
        tone: resultTone,
        length: resultLength,
        usePythonServer:
          appConfig?.features?.aiToolsPythonEndpoint?.enabled || false,
      })
      .then((res) => {
        setFeedbackRecorded(false);
        setAiResponseId(res.data?.id);
        const result = `<b>Subject:-</b> ${JSON.parse(res.data?.content || {})?.email.subject_line}

${JSON.parse(res.data?.content || {})?.email?.body}`;
        setResult(result);
      })
      .catch((e) => {
        console.error(e);
        message.error(
          'There was a problem while generating the job email writer.'
        );
      })
      .finally(() => {
        setResultLoading(false);
      });
  };

  if (showHistory) {
    if (historyLoading) {
      return <Loader />;
    } else
      return (
        <div
          style={{
            width: '75%',
            flexDirection: 'column',
            display: 'flex',
            margin: '2rem auto',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              marginBottom: '2rem',
            }}
          >
            <LeftOutlined
              style={{ color: '#40a9ff', fontSize: '18px' }}
              onClick={() => setShowHistory(false)}
            />
            <div className={'sub-heading'}>{t(`${toolname} History`)}</div>
          </div>

          {!headlineHistory?.length && (
            <div className={styles.noContact}>
              <div>
                <img src={nodocument} height={110} alt="no contact" />
              </div>
              <div className={styles.heading}>
                {t(`No ${toolname}s Generated`)}
              </div>
              <div style={{ width: '60%' }}>
                {t(`You have not generated any ${toolname}s yet.`)}
              </div>

              <Button
                className={'createBtn'}
                onClick={() => setShowHistory((prev) => !prev)}
              >
                {t('getStarted')}
              </Button>
            </div>
          )}

          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
          >
            {headlineHistory?.map((historyItem: any) => (
              <AIToolHistoryCard
                historyObject={historyItem}
                onViewDetails={viewDetailHandler}
              />
            ))}
          </div>
        </div>
      );
  } else {
    return (
      <>
        {openImportJD && (
          <ImportJobDescriptionModal
            open={openImportJD}
            setOpen={setOpenImportJD}
            onImportComplete={({
              jobDescription,
              companyName,
              jobTitle,
            }: any) => {
              setJobDescription(jobDescription);
              setJobTitle(jobTitle);
            }}
          />
        )}
        <AiDocuments
          setResumeForResult={setResumeForResult}
          setIsModalVisible={setIsModalVisible}
          isModalVisible={isModalVisible}
        />
        <Modal
          open={openTemplateModal}
          getContainer={false}
          footer={null}
          style={{
            zIndex: 2147483647,
            borderRadius: '12px !important',
            top: '3rem',
          }}
          onCancel={() => {
            setOpenTemplateModal(false);
            window?.chrome?.runtime?.sendMessage({
              analytics: {
                eventAction: `Closed template preview modal`,
              },
            });
          }}
        >
          <CoverLetterTemplates
            selectedTemplate={selectedTemplate}
            templates={templates}
            setSelectedTemplate={setSelectedTemplate}
            closeModal={() => {
              setOpenTemplateModal(false);
            }}
          />
        </Modal>
        <Row className={styles.root}>
          <Col
            style={{ height: '85vh' }}
            span={22}
            md={11}
            className={styles.contentBox}
          >
            {!result ? (
              <>
                <div className={styles.inputSectionHeader}>
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span className="sub-heading">{t(`${toolname}`)}</span>
                    </div>
                  </div>
                  <div>
                    <Button
                      onClick={() => setShowHistory(true)}
                      style={{ color: 'black' }}
                      className="tertiary-btn"
                    >
                      {t('ViewHistory')}{' '}
                      <HistoryOutlined style={{ color: '#1570EF' }} />
                    </Button>
                  </div>
                </div>
                <div className={styles.inputsForm}>
                  <>
                    <div style={{ display: 'flex', alignItems: 'end' }}>
                      {t('Company Details or Job Description')}{' '}
                      <div
                        style={{
                          display: 'flex',
                          position: 'relative',
                          marginLeft: 'auto',
                        }}
                      >
                        <Button onClick={() => setOpenImportJD(true)}>
                          {t('ImportfromBoard')}
                        </Button>
                      </div>
                    </div>

                    <Input.TextArea
                      style={{ height: 100 }}
                      value={jobDescription}
                      placeholder={t(
                        'Enter information about the company, job description or any information we could use to personalize the email.'
                      )}
                      className={styles.input}
                      onChange={(e) => setJobDescription(e.target.value)}
                    />

                    <div>{t('Recipient Details')}</div>
                    <Input
                      type="text"
                      placeholder={t(
                        'Enter information about the receiver like name, role, etc'
                      )}
                      className={styles.input}
                      value={aboutTheReceiver}
                      onChange={(e) => setAboutTheReciever(e.target.value)}
                    />
                    <div>{t('TargetJobTitle')}*</div>
                    <Input
                      type="text"
                      placeholder={t('EnterJobTitle')}
                      className={styles.input}
                      value={jobTitle}
                      onChange={(e) => setJobTitle(e.target.value)}
                    />

                    <div>{t('Email Type')}*</div>

                    <Select
                      style={{
                        width: '250px',
                        marginTop: '0.5rem',
                        marginBottom: '1rem',
                      }}
                      defaultValue={'job_outreach'}
                      options={[
                        {
                          value: 'job_outreach',
                          label: t('Job Outreach'),
                        },
                        {
                          value: 'referral',
                          label: t('Referral'),
                        },
                        {
                          value: 'networking',
                          label: t('Networking'),
                        },
                        {
                          value: 'offer_negotiation',
                          label: t('Offer Negotiation'),
                        },
                      ]}
                      placeholder={t('Select Email type')}
                      onChange={(e) => setEmailType(e)}
                    />

                    <Divider style={{ margin: '12px 0' }} />
                    {templates.length > 0 && (
                      <>
                        <div
                          className="large-text"
                          style={{ marginTop: '1.5rem' }}
                        >
                          {t('Template')}
                        </div>
                        <div
                          className="templateCollapse"
                          style={{ margin: '1rem 0' }}
                        >
                          {!selectedTemplate || templateChangeMode ? (
                            <Collapse
                              defaultActiveKey={templateChangeMode ? '1' : '0'}
                              expandIconPosition="right"
                              expandIcon={({ isActive }) => (
                                <RightOutlined rotate={isActive ? 270 : 90} />
                              )}
                            >
                              <CollapsePanel
                                header={
                                  <div className="large-text">
                                    {t('SelectTemplate')}
                                  </div>
                                }
                                key="1"
                              >
                                <div>
                                  {templates?.map((template) => (
                                    <div
                                      style={{
                                        padding: '1rem',
                                        margin: '1rem',
                                        borderRadius: '8px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        border: '1px solid #E1EDFF',
                                      }}
                                    >
                                      <div>
                                        <FileTextFilled
                                          style={{
                                            fontSize: '16px',
                                            color: '#1570EF',
                                            marginRight: '10px',
                                          }}
                                        />
                                        {template.title}
                                      </div>

                                      <div>
                                        <Button
                                          onClick={() => {
                                            setOpenTemplateModal(true);
                                            setSelectedTemplate(template.title);
                                            analyticsEvent(
                                              'Open template preview clicked',
                                              template.title
                                            );
                                          }}
                                          type="link"
                                        >
                                          {t('Preview')}{' '}
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            setSelectedTemplate(template.title);
                                            setTemplateChangeMode(false);
                                            analyticsEvent(
                                              'Use template clicked',
                                              template.title
                                            );
                                          }}
                                        >
                                          {t('UseTemplate')}
                                        </Button>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </CollapsePanel>
                            </Collapse>
                          ) : (
                            <div
                              style={{
                                padding: '1rem',
                                alignItems: 'center',
                                borderRadius: '8px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                border: '1px solid #E1EDFF',
                              }}
                            >
                              <div>
                                <FileTextFilled
                                  style={{
                                    fontSize: '16px',
                                    color: '#1570EF',
                                    marginRight: '10px',
                                  }}
                                />
                                {selectedTemplate}
                              </div>

                              <div>
                                <Button
                                  onClick={() => {
                                    setOpenTemplateModal(true);
                                    analyticsEvent(
                                      'Open template preview',
                                      selectedTemplate
                                    );
                                  }}
                                  type="link"
                                >
                                  {t('Preview')}{' '}
                                </Button>
                                <Button
                                  onClick={() => {
                                    setTemplateChangeMode(true);
                                    analyticsEvent(
                                      'Change selected template clicked',
                                      selectedTemplate
                                    );
                                  }}
                                >
                                  {t('Change')}
                                </Button>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    <div className="large-text" style={{ margin: '0.5rem 0' }}>
                      {t('YourProfile')}
                    </div>
                    <div>
                      <Radio.Group
                        className={styles.input}
                        style={{ display: 'flex', gap: '0.75rem' }}
                        onChange={(e: any) => {
                          setProfileSelection(e.target?.value);
                        }}
                        value={profileSelection}
                      >
                        <div className={styles.radioInput}>
                          <Radio value={'resume'}>{t('ResumeUpload')}</Radio>
                        </div>

                        <div className={styles.radioInput}>
                          <Radio value={'linkedin'}>
                            {t('UseLinkedinProfile')}
                          </Radio>
                        </div>
                      </Radio.Group>
                      {profileSelection === 'resume' ? (
                        <div>
                          {!resumeForResult ? (
                            <div
                              style={{
                                border: '1px solid #e1edff',
                                borderRadius: '8px',
                                marginTop: '1rem',
                              }}
                            >
                              <div
                                style={{
                                  borderBottom:
                                    '1px solid rgba(225, 237, 255, 1)',
                                  borderRadius: '8px 8px 0 0',
                                  padding: '0.75rem 1rem',
                                  background: 'rgba(248, 251, 254, 1)',
                                }}
                                className={'bold-sub-text'}
                              >
                                {t('UploadYourResume')}
                              </div>

                              <div className={styles.fullWidth}>
                                <div
                                  style={{
                                    flexDirection: 'column',
                                    padding: '1.5rem 0',
                                  }}
                                  className={clsx('flex-center')}
                                >
                                  <img src={fileAdd} alt="file add" />
                                  <div className="bold-text">
                                    {t('AddyourResume')}
                                  </div>
                                  <div className="small-sub-text">
                                    {t('fileNameCannotFormat')}
                                  </div>
                                  <Button
                                    style={{ marginTop: '1rem' }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setIsModalVisible(true);
                                    }}
                                    type="primary"
                                    className={'createBtn'}
                                  >
                                    {t('ChooseorUpload')}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <PdfParser
                              onTextExtracted={(e: any) => {
                                setResumeText(e);
                              }}
                              selectedResume={resumeForResult}
                              isModalVisible={isModalVisible}
                              setIsModalVisible={setIsModalVisible}
                              setSelectedResume={setResumeForResult}
                            />
                          )}
                        </div>
                      ) : (
                        !linkedinProfile && (
                          <div>
                            <div
                              style={{
                                border: '1px solid #e1edff',
                                borderRadius: '8px',
                                marginTop: '1rem',
                              }}
                            >
                              <div
                                style={{
                                  borderBottom:
                                    '1px solid rgba(225, 237, 255, 1)',
                                  borderRadius: '8px 8px 0 0',
                                  padding: '0.75rem 1rem',
                                  background: 'rgba(248, 251, 254, 1)',
                                }}
                                className={'bold-sub-text'}
                              >
                                {t('LinkedinProfile')}
                              </div>

                              <div className={styles.fullWidth}>
                                <div
                                  style={{
                                    flexDirection: 'column',
                                    padding: '1.5rem 0',
                                  }}
                                  className={clsx('flex-center')}
                                >
                                  <div className="bold-text">
                                    {t('LinkedinProfilenotfound')}{' '}
                                  </div>
                                  <div
                                    style={{
                                      color: '#96a0b5',
                                      fontSize: '12px',
                                      fontWeight: '300',
                                    }}
                                  >
                                    {t('startUsingChromeExtension')}
                                  </div>
                                  <Button
                                    style={{ marginTop: '1rem' }}
                                    type="primary"
                                    onClick={() =>
                                      window.open(
                                        'https://chrome.google.com/webstore/detail/careerflows-linkedin-opti/iadokddofjgcgjpjlfhngclhpmaelnli'
                                      )
                                    }
                                  >
                                    {t('Download')} <DownloadOutlined />
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                    <Divider style={{ margin: '24px 0 12px 0' }} />

                    <div
                      className="advancedOptionsCollapse"
                      style={{ margin: '1rem 0' }}
                    >
                      <Collapse
                        ghost
                        expandIconPosition="right"
                        expandIcon={({ isActive }) => (
                          <RightOutlined rotate={isActive ? 270 : 90} />
                        )}
                      >
                        <CollapsePanel
                          header={
                            <div className="large-text">
                              {t('AdvancedSettings')}
                            </div>
                          }
                          key="1"
                        >
                          <div
                            style={{
                              border: '1px solid #E5EBF0',
                              background: '#FBFCFC',
                              padding: '0 1rem',
                              margin: '0.5rem 0',
                            }}
                          >
                            <div style={{ margin: '0.5rem 0' }}>
                              {t('AdditionalContext')}
                            </div>
                            <Input.TextArea
                              style={{ height: 100 }}
                              value={additionalContext}
                              placeholder={t(
                                'Enter any additional instructions or information'
                              )}
                              className={styles.input}
                              onChange={(e) =>
                                setAdditionalContext(e.target.value)
                              }
                            />
                            <div style={{ margin: '0.5rem 0' }}>
                              {t('Tone')}
                            </div>
                            <div>
                              <Radio.Group
                                className={styles.input}
                                style={{
                                  display: 'flex',
                                  gap: '0.75rem',
                                  flexWrap: 'wrap',
                                }}
                                onChange={(e: any) =>
                                  setResultTone(e.target?.value)
                                }
                                value={resultTone}
                              >
                                <div>
                                  <Radio value={'professional'}>
                                    {t('Professional')}
                                  </Radio>
                                </div>
                                <div>
                                  <Radio value={'casual'}>{t('Casual')}</Radio>
                                </div>
                                <div>
                                  <Radio value={'enthusiastic'}>
                                    {t('Enthusiastic')}
                                  </Radio>
                                </div>
                                <div>
                                  <Radio value={'informational'}>
                                    {t('Informational')}
                                  </Radio>
                                </div>
                              </Radio.Group>
                            </div>
                            <div>{t('Length')}</div>
                            <div>
                              <Radio.Group
                                className={styles.input}
                                style={{ display: 'flex', gap: '0.75rem' }}
                                onChange={(e: any) =>
                                  setResultLength(e.target?.value)
                                }
                                value={resultLength}
                              >
                                <div>
                                  <Radio value={'short'}>{t('Short')}</Radio>
                                </div>
                                <div>
                                  <Radio value={'medium'}>{t('Medium')}</Radio>
                                </div>
                                <div>
                                  <Radio value={'long'}>{t('Long')}</Radio>
                                </div>
                              </Radio.Group>
                            </div>
                            <div style={{ marginTop: '1.5rem' }}>
                              {t('Language')}
                            </div>
                            <LanguageDropdown
                              setSelectedLanguage={setSelectedLanguage}
                              selectedLanguage={selectedLanguage}
                              languageChangeMode={languageChangeMode}
                              setLanguageChangeMode={setLanguageChangeMode}
                              style={{ margin: '0.75rem' }}
                            />
                          </div>
                        </CollapsePanel>
                      </Collapse>
                    </div>
                  </>
                </div>
              </>
            ) : (
              <FollowupInput
                followupPrompt={followupPrompt}
                setFollowupPrompt={setFollowupPrompt}
                toolname={toolname}
              />
            )}
            <div
              style={{
                padding: '1rem  1.5rem 1rem 1.5rem',
                display: 'flex',
                gap: '1rem',
              }}
              className={styles.generateDiv}
            >
              {result && (
                <Button
                  style={{ width: '100%', marginBottom: '1rem' }}
                  className={'tertiary-btn'}
                  size="large"
                  onClick={() => setResult('')}
                  disabled={resultLoading}
                >
                  {t('iAmOkayBack')}
                </Button>
              )}
              <Button
                style={{ width: '100%' }}
                type="primary"
                size="large"
                loading={resultLoading}
                onClick={onGenerate}
                disabled={
                  userInfo.userType !== 'premium' ||
                  (!linkedinProfile && !resumeForResult)
                }
                className={'createBtn'}
              >
                {t('Generate')}
              </Button>
            </div>
          </Col>
          <Col
            style={{ height: 'fit-content' }}
            span={22}
            md={11}
            className={styles.contentBox}
          >
            <AIResults
              sectionName={t('email-writer')}
              resultLoading={resultLoading}
              feedbackRecorded={feedbackRecorded}
              result={result}
              setResult={setResult}
              onGenerate={onGenerate}
              setUpgrade={setUpgrade}
              aiResponseId={aiResponseId}
              setFeedbackRecorded={setFeedbackRecorded}
            />
          </Col>
        </Row>
      </>
    );
  }
};

export default React.memo(JobOutreachEmail);
