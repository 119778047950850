import { Input } from 'antd';

function FollowupInput({ followupPrompt, setFollowupPrompt, toolname }: any) {
  return (
    <div style={{ padding: '2rem', height: '80vh' }}>
      <div
        className={'large-text bold-text'}
        style={{ marginBottom: '0.5rem' }}
      >
        🙌 We hope you like the {toolname}
      </div>
      <div
        style={{
          fontSize: '14px',
          fontWeight: '300',
          lineHeight: '18px',
        }}
      >
        If you still need us to modify it for you, here you can write a prompt
        for what needs to be changed or added
      </div>
      <Input.TextArea
        style={{ height: 80, marginTop: '1.5rem' }}
        value={followupPrompt}
        placeholder={`Enter a follow-up prompt for any changes you would like to see in your ${toolname}`}
        onChange={(e) => setFollowupPrompt(e.target.value)}
      />
    </div>
  );
}

export default FollowupInput;
