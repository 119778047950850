import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  FileTextFilled,
} from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

const CoverLetterTemplates = ({
  templates,
  selectedTemplate,
  setSelectedTemplate,
  closeModal,
}) => {
  const [currentTemplateIndex, setCurrentTemplateIndex] = useState(0);
  const currentTemplate = templates[currentTemplateIndex];
  const { t } = useTranslation();

  const handlePrev = () => {
    if (currentTemplateIndex > 0) {
      setCurrentTemplateIndex((index) => index - 1);
    }
  };

  const handleNext = () => {
    if (currentTemplateIndex < templates.length - 1) {
      setCurrentTemplateIndex((index) => index + 1);
    }
  };

  useEffect(() => {
    const templateIndex = templates.findIndex(
      (template) => template.title === selectedTemplate
    );
    setCurrentTemplateIndex(templateIndex);
  }, [selectedTemplate, templates]);

  return (
    <div style={{ padding: '1rem' }}>
      <div className={'sub-heading'}>
        <FileTextFilled style={{ color: '#1570EF', marginRight: '5px' }} />
        {currentTemplate?.title}
      </div>
      <div className={styles.clTemplateBody}>
        {currentTemplate?.sections.map((section) => (
          <div style={{ marginTop: '10px' }}>
            <div className={styles.clTemplateBodySectionHeader}>
              {section?.heading}
            </div>
            <div className={styles.clTemplateBodySectionContent}>
              {section?.content.map((content) => (
                <div className={styles.clTemplatePoint}>
                  <p style={{ whiteSpace: 'pre-wrap' }}>{content} </p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.clTemplateFooter}>
        <Button disabled={currentTemplateIndex < 1} ghost onClick={handlePrev}>
          <ArrowLeftOutlined />
          {t('Prev')}
        </Button>
        <Button
          onClick={() => {
            setSelectedTemplate(currentTemplate?.title);
            closeModal();
          }}
          type="primary"
          className="primary-btn"
        >
          {t('UseTemplate')}
        </Button>{' '}
        <Button
          disabled={currentTemplateIndex > templates.length - 2}
          onClick={handleNext}
        >
          {t('Next')} <ArrowRightOutlined />
        </Button>
      </div>
    </div>
  );
};

export default CoverLetterTemplates;
