import axios, { InternalAxiosRequestConfig } from 'axios';
import { auth } from '../utils/firebase/firebaseIndex';
import { aiServiceBasePath } from '../utils/apiPaths';

export const aiServiceClient = axios.create({
  baseURL: aiServiceBasePath,
});

const authInterceptor = async (config: InternalAxiosRequestConfig) => {
  config.headers['sourceApp'] = `JOB_SEEKER`;
  config.headers['institute'] = localStorage.getItem('selectedInstitute');
  if (config && config.headers && auth.currentUser) {
    const accessToken = await auth.currentUser.getIdToken();
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return config;
};

export const pythonAiServiceClient = axios.create({
  baseURL: process.env.VITE_APP_PYTHON_BACKEND_BASE_URL,
});

aiServiceClient.interceptors.request.use(authInterceptor, (error: any) =>
  Promise.reject(error)
);

pythonAiServiceClient.interceptors.request.use(authInterceptor, (error: any) =>
  Promise.reject(error)
);

aiServiceClient.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    config.headers['sourceApp'] = `JOB_SEEKER`;
    config.headers['institute'] = localStorage.getItem('selectedInstitute');
    if (config && config.headers && auth.currentUser) {
      const accessToken = await auth.currentUser.getIdToken();
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },

  (error: any) => Promise.reject(error)
);

const rewriteAIDescription = (body: any) => {
  return aiServiceClient.post('/resume-builder/ai/rewriteExperience', body);
};

const summarizeText = (body: any) => {
  return aiServiceClient.post('/resume-builder/ai/summarizeText', body);
};

const rewriteAIProfessionalSummary = (body: any) => {
  return aiServiceClient.post('/resume-builder/ai/professionalSummary', body);
};

const rewriteProject = (body: any) => {
  return aiServiceClient.post('/resume-builder/ai/rewriteProject', body);
};

const personalBrandStatement = (body: any) => {
  return pythonAiServiceClient.post(
    '/ai-services/v1/personal-statement/generate',
    body
  );
};

const emailWriter = (body: any) => {
  return pythonAiServiceClient.post('/ai-services/v1/email/generate', body);
};

const aiService = {
  rewriteAIDescription,
  summarizeText,
  rewriteAIProfessionalSummary,
  rewriteProject,
  personalBrandStatement,
  emailWriter,
};

export default aiService;
