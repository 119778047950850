import { PlusSquareOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import React, { useCallback } from 'react';
import { pdfjs } from 'react-pdf';
import resume from '../../images/Vectorresumexp.svg';
import style from './PdfParser.module.scss';
import { DOMParser } from 'xmldom';
import PizZip from 'pizzip';
import { useTranslation } from 'react-i18next';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PdfParserProps {
  onTextExtracted: (text: string) => void;
  selectedResume: any;
  setSelectedResume?: any;
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean) => void;
  children?: any;
  styles?: any;
}

const PdfParser: React.FC<PdfParserProps> = ({
  onTextExtracted,
  selectedResume,
  setIsModalVisible,
  children,
  styles,
}) => {
  const { t } = useTranslation();
  const extractTextFromResume = useCallback(async () => {
    if (selectedResume?.resume?.type === 'application/pdf') {
      const pdfBlob = new Blob([selectedResume?.resume], {
        type: 'application/pdf',
      });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const pdf = await pdfjs.getDocument(pdfUrl).promise;
      const pageTexts = await Promise.all(
        Array.from({ length: pdf.numPages }, (_, i) =>
          pdf.getPage(i + 1).then((page) => page.getTextContent())
        )
      );
      const texts = pageTexts.flatMap((text) =>
        text.items.map((item) => {
          return 'str' in item ? item.str : '';
        })
      );
      const extractedText = texts.join(' ');

      if (extractedText?.length === 0) {
        message.error(t('oopsWeCouldntProcessResume'));
      }
      onTextExtracted(extractedText);
    }
    if (
      selectedResume?.resume?.type ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      selectedResume?.resume?.type === 'application/msword'
    ) {
      const fileReader = new FileReader();
      fileReader.onload = async () => {
        const arrayBuffer = fileReader.result as ArrayBuffer;
        const zip = new PizZip(arrayBuffer);
        const xmlContent = zip.file('word/document.xml')?.asText();
        const parser = new DOMParser();
        const xml = parser.parseFromString(xmlContent || '', 'application/xml');
        const paragraphsXml = xml.getElementsByTagName('w:p');
        const paragraphs: string[] = [];

        for (let i = 0, len = paragraphsXml.length; i < len; i++) {
          let fullText = '';
          const textsXml = paragraphsXml[i].getElementsByTagName('w:t');
          for (let j = 0, len2 = textsXml.length; j < len2; j++) {
            const textXml = textsXml[j];
            if (textXml.childNodes) {
              fullText += textXml.childNodes[0].nodeValue || '';
            }
          }
          if (fullText) {
            paragraphs.push(fullText);
          }
        }
        const extractedText = paragraphs.join(' ');
        if (extractedText?.length === 0) {
          message.error(t('oopsWeCouldntProcessResume'));
        }
        onTextExtracted(extractedText);
      };

      fileReader.onerror = (err) => {
        console.error(err);
      };

      fileReader.readAsArrayBuffer(selectedResume?.resume);
    }

    if (typeof selectedResume?.resume === 'string') {
      if (selectedResume?.resume?.length === 0) {
        message.error(t('oopsWeCouldntProcessResume'));
      }
      onTextExtracted(selectedResume?.resume);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResume]);

  React.useEffect(() => {
    if (selectedResume?.resume) {
      extractTextFromResume();
    }
  }, [extractTextFromResume, selectedResume]);

  return (
    <div>
      {selectedResume ? (
        <div style={styles}>
          {children ? (
            <>{children}</>
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: 'inherit',
                }}
              >
                <img
                  src={resume}
                  height={'20px'}
                  alt="resume_icon"
                  style={{
                    marginRight: '0.75rem',
                  }}
                />
                <div className={style.resumeTitle}>{selectedResume?.name}</div>
              </div>
            </>
          )}

          <div style={{ display: 'flex' }}>
            <Button
              onClick={() => setIsModalVisible(true)}
              className={style.createbtn}
            >
              {t('Change')}
            </Button>
          </div>
        </div>
      ) : (
        <Button className="createBtn" onClick={() => setIsModalVisible(true)}>
          <label htmlFor="pdf-input" style={{ display: 'inline-block' }}>
            {t('AddDocument')}
          </label>{' '}
          <PlusSquareOutlined style={{ marginTop: '0.2rem' }} />
        </Button>
      )}
    </div>
  );
};

export default PdfParser;
