import React, { useState } from 'react';
import Paragraph, { ParagraphProps } from 'antd/es/typography/Paragraph';
import { Tooltip } from 'antd';
import { EllipsisConfig } from 'antd/lib/typography/Base';
import './TooltipParagraph.scss';

const TooltipParagraph: React.FC<
  ParagraphProps & { icon?: React.ReactNode }
> = ({ children, ellipsis, icon, ...props }) => {
  const [truncated, setTruncated] = useState(false);

  return (
    <Tooltip title={truncated ? children : undefined}>
      {icon && icon}
      <Paragraph
        {...props}
        ellipsis={{ ...(ellipsis as EllipsisConfig), onEllipsis: setTruncated }}
        className={'tooltipParagraph'}
      >
        {/* NOTE: Fragment is necessary to avoid showing the title */}
        <>{children}</>
      </Paragraph>
    </Tooltip>
  );
};

export default TooltipParagraph;
