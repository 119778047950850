import { RightOutlined } from '@ant-design/icons';
import { Button, Collapse } from 'antd';
import { analyticsEvent } from '@careerflow/common-components/dist/utils/Analytics';
import { useTranslation } from 'react-i18next';
import { AiToolsLanguages } from '@careerflow/common-components/dist/constants/LanguageDropdown';

function LanguageDropdown({
  selectedLanguage,
  languageChangeMode,
  setSelectedLanguage,
  setLanguageChangeMode,
  style = {},
}: any) {
  const { t } = useTranslation();
  return (
    <div className="templateCollapse" style={{ margin: '1rem 0' }}>
      {!selectedLanguage || languageChangeMode ? (
        <Collapse
          style={{ borderRadius: '2px' }}
          defaultActiveKey={languageChangeMode ? '1' : '0'}
          expandIconPosition={'end'}
          expandIcon={({ isActive }) => (
            <RightOutlined rotate={isActive ? 270 : 90} />
          )}
        >
          <Collapse.Panel
            header={
              <div style={{ ...style, margin: '0.75rem' }}>
                {t('SelectLanguage')}
              </div>
            }
            key="1"
          >
            <div>
              {Object.keys(AiToolsLanguages)?.map((language: any) => (
                <div
                  style={{
                    padding: '1rem',
                    margin: '1rem',
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    border: '1px solid #E1EDFF',
                  }}
                >
                  <div>{AiToolsLanguages?.[language]}</div>

                  <div>
                    <Button
                      onClick={() => {
                        setSelectedLanguage(language);
                        setLanguageChangeMode(false);
                        analyticsEvent(
                          'Use language clicked',
                          AiToolsLanguages?.[language]
                        );
                      }}
                    >
                      {t('SelectLanguage')}
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </Collapse.Panel>
        </Collapse>
      ) : (
        <div
          style={{
            padding: '1rem',
            alignItems: 'center',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'space-between',
            border: '1px solid #E1EDFF',
          }}
        >
          <div>{AiToolsLanguages?.[selectedLanguage]}</div>

          <div>
            <Button
              onClick={() => {
                setLanguageChangeMode(true);
                analyticsEvent(
                  'Change selected language clicked',
                  selectedLanguage
                );
              }}
            >
              {t('Change')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default LanguageDropdown;
