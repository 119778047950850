import { useSpring, animated } from 'react-spring';

const FadeInComponent = ({ children, duration, delay }: any) => {
  // Define the animation config
  const fadeInAnimation = useSpring({
    opacity: 1,
    config: {
      duration: duration || 500,
    },
    from: { opacity: 0 },
    delay: delay || 0,
  });

  return (
    <animated.div style={{ ...fadeInAnimation, width: '100%' }}>
      {children}
    </animated.div>
  );
};
export default FadeInComponent;
