import { Button, Col, message, Modal, Row, Tooltip } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';
import axios from 'axios';
import { getDownloadURL, ref } from 'firebase/storage';
import React, { useEffect, useState } from 'react';
import uploadFile from '../../../images/uploadFile.svg';

import { storage } from '../../../utils/firebase/firebaseIndex';
import Loader from '../../Loader';
import styles from '../HeadlineGenerator.module.scss';
import careerflowLogo from '../../../images/careerflowLogo.svg';
import { useTranslation } from 'react-i18next';
import { useIndexedDB } from 'react-indexed-db-hook';
import { jobTrackerApiPath } from '../../../utils/apiPaths';
import { AppContext, InitialState } from '../../../auth';
import DocumentUploadModal from '@careerflow/common-components/dist/components/Documents/DocumentUploadModal';
import { handleOnKeyDown } from '../../../utils/eventListeners';

interface Props {
  setResumeForResult: any;
  setIsModalVisible: any;
  isModalVisible: boolean;
  setDefaultResume?: any;
  setDocumentsDataCoverLetter?: any;
}

const AiDocuments: React.FC<Props> = ({
  setResumeForResult,
  setIsModalVisible,
  isModalVisible,
  setDefaultResume,
  setDocumentsDataCoverLetter,
}) => {
  const { t } = useTranslation();

  const { add, getByIndex } = useIndexedDB('resume');

  const [isCreateDocumentVisible, setIsCreateDocumentVisible] = useState(false);

  const [selectedResume, setSelectedResume] = useState<any>();

  const [documentsLoader, setDocumentsLoader] = useState(false);

  const [documentsData, setDocumentsData] = useState<any>([]);
  const [fileUploaded, setFileUploaded] = useState<any>(false);

  const { userInfo } = React.useContext(AppContext) as InitialState;

  const getCareerflowResume = async () => {
    let fetchedResumeData: any = {};
    const resumeData: any = await axios.get(
      `${jobTrackerApiPath}/resumes/${selectedResume?.resumeId}`
    );
    fetchedResumeData = resumeData?.data;

    setResumeForResult({
      name: selectedResume?.title || '',
      resume: fetchedResumeData?.rawText,
      id: selectedResume?.id,
    });

    setIsModalVisible(false);
  };

  const getDocuments = React.useCallback(() => {
    setDocumentsLoader(true);
    axios
      .get(`${jobTrackerApiPath}/documents`)
      .then((res) => {
        setDocumentsData(
          res.data.sort(
            (doc1: any, doc2: any) =>
              doc2?.created?._seconds - doc1?.created?._seconds
          )
        );
        if (setDocumentsDataCoverLetter)
          setDocumentsDataCoverLetter(
            res.data.sort(
              (doc1: any, doc2: any) =>
                doc2?.created?._seconds - doc1?.created?._seconds
            )
          );
        const isSrcExtension =
          new URL(window.location.href).searchParams.get('src') === 'extension';
        if (!isSrcExtension && setDefaultResume) {
          const defResume = res.data.find(
            (res: any) => res.id === userInfo.defaultResumeId
          );

          if (defResume) {
            setDefaultResume(defResume);
          }
        }
      })
      .catch((err) => {
        message.error(
          'There was a problem in getting your documents. Please try again later.'
        );
        console.error('Error in getting documents', err);
      })
      .finally(() => setDocumentsLoader(false));
  }, [setDefaultResume, setDocumentsDataCoverLetter, userInfo.defaultResumeId]);

  useEffect(() => {
    getDocuments();
  }, [getDocuments]);

  return (
    <>
      <DocumentUploadModal
        isCreateDocumentVisible={isCreateDocumentVisible}
        setIsCreateDocumentVisible={setIsCreateDocumentVisible}
        fileUploaded={fileUploaded}
        setFileUploaded={setFileUploaded}
        getDocuments={getDocuments}
        link={true}
      />
      <Modal
        title={t('Documents')}
        open={isModalVisible}
        okButtonProps={{ disabled: !selectedResume }}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        onOk={
          selectedResume?.type === 'careerflowResume'
            ? () => getCareerflowResume()
            : async () => {
                if (!selectedResume?.id)
                  return message.warning('Please select a document first');

                getByIndex('name', selectedResume?.title)
                  .then(async (res) => {
                    if (res) {
                      setResumeForResult(res);
                    } else {
                      const storageRef = ref(
                        storage,
                        selectedResume?.documentPath
                      );
                      const downloadUrl: string =
                        await getDownloadURL(storageRef);

                      axios
                        .get(downloadUrl, {
                          responseType: 'blob',
                        })
                        .then((res: any) => {
                          add({
                            name: selectedResume?.title,
                            resume: res.data,
                          }).then(() => {
                            setResumeForResult({
                              name: selectedResume?.title || '',
                              resume: res.data,
                              id: selectedResume?.id,
                            });
                          });
                        })
                        .catch((e) => {
                          console.error(e);
                          message.error(
                            'There was a error getting this resume.'
                          );
                        });
                    }
                  })
                  .catch(async (err) => {
                    console.error(err);
                    const storageRef = ref(
                      storage,
                      selectedResume?.documentPath
                    );
                    try {
                      const downloadUrl: string =
                        await getDownloadURL(storageRef);
                      const res = await axios.get(downloadUrl, {
                        responseType: 'blob',
                      });

                      add({
                        name: selectedResume?.title,
                        resume: res.data,
                      })
                        .then(() => {
                          setResumeForResult({
                            name: selectedResume?.title || '',
                            resume: res.data,
                            id: selectedResume?.id,
                          });
                        })
                        .catch((err) => {
                          console.error(err);
                          setResumeForResult({
                            name: selectedResume?.title || '',
                            resume: res.data,
                            id: selectedResume?.id,
                          });
                        });
                    } catch {
                      message.error('There was a error getting this resume.');
                    }
                  });

                setIsModalVisible(false);
              }
        }
      >
        <div style={{ height: '400px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              className="createBtn"
              style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }}
              onClick={() => {
                setIsCreateDocumentVisible(true);
              }}
            >
              <label htmlFor="pdf-input" style={{ display: 'inline-block' }}>
                {t('UploadNewDocument')}
              </label>{' '}
              <PlusSquareOutlined style={{ marginTop: '0.2rem' }} />
            </Button>
          </div>
          {documentsLoader ? (
            <Loader />
          ) : documentsData?.length > 0 ? (
            <div
              style={{
                marginLeft: '0.7rem',
                maxHeight: '330px',
                overflowY: 'scroll',
              }}
            >
              {documentsData?.map((resume: any, index: any) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      setSelectedResume(resume);
                    }}
                    onKeyDown={(e) =>
                      handleOnKeyDown(e, () => {
                        setSelectedResume(resume);
                      })
                    }
                    style={{
                      border:
                        selectedResume === resume
                          ? '1px solid #4280dc'
                          : '1px solid #E1EDFF',
                      background: selectedResume === resume ? '#e6f4ff' : '',
                    }}
                    className={styles.fileSelection}
                    role="button"
                    tabIndex={0}
                  >
                    <Row style={{ width: '100%' }}>
                      <Col
                        span={22}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <img
                          src={uploadFile}
                          alt="upload file icon"
                          style={{ marginRight: '0.5rem' }}
                        />
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <div
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {resume?.title}
                          </div>
                          {resume?.type === 'careerflowResume' ? (
                            <>
                              <Tooltip title={t('CreatedwithCareerflow')}>
                                <img
                                  src={careerflowLogo}
                                  alt="careerflow icon"
                                  height={14}
                                  style={{ marginLeft: '8px' }}
                                />
                              </Tooltip>
                            </>
                          ) : null}
                        </div>
                      </Col>
                      <Col
                        span={2}
                        style={{ display: 'flex', alignItems: 'center' }}
                      ></Col>
                    </Row>
                  </div>
                );
              })}
            </div>
          ) : (
            <p
              style={{
                marginTop: '0.5rem',
                marginLeft: '2rem',
              }}
            >
              {t('noAttachmentsFound')}
            </p>
          )}
        </div>
      </Modal>
    </>
  );
};

export default AiDocuments;
