import { Spin } from 'antd';
import React from 'react';
import styles from './Loader.module.scss';
import { useTranslation } from 'react-i18next';

interface LoaderProps {
  style?: any;
}

const Loader: React.FC<LoaderProps> = ({ style }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.loader} style={style}>
      <Spin tip={t('Loading')} size="large"></Spin>
    </div>
  );
};

export default Loader;
