export const CareerflowSaleData = {
  saleEndTimestamp: 1733212740000,
  textPrimaryColorCode: '#FF8908',
  couponCode: 'THANKSGIVING24',
  eventName: 'ThanksGiving',
  eventDateText: '28th November',
  offerEndDateText: '2nd December',
  discountPercent: '50',
  imageUrl:
    'https://ik.imagekit.io/sanuj/ThanksgivingBanner-01.png?updatedAt=1732803463268',
  stripeUrl:
    'https://buy.stripe.com/aEUdU37a8eilcpycNf?prefilled_promo_code=THANKSGIVING24',
};
