import { Button, Col, Collapse, Divider, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ThemeColors from '../../../theme/themeColors';
import FadeInComponent from '../../Transitions/FadeIn';
import styles from './index.module.scss';
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import axios from 'axios';
import { config } from '../../../utils/apiPaths';
import './Plans.scss';
import { ReactComponent as Strike } from '../images/Vector.svg';
import { ReactComponent as Bullet } from '../images/Bullet.svg';
import { ReactComponent as Aitools } from '../images/Aitools.svg';

import People from '../images/people.webp';
import { analyticsEvent } from '@careerflow/common-components/dist/utils/Analytics';
import { useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import { AppContext } from '../../../auth';

const { Panel } = Collapse;

export const features = {
  freeFeatures: [
    {
      title: 'One Free AI Resume',
      description:
        'Build a professional resume with customizable features to showcase your skills. (1 Resume, Basic Features)',
    },
    {
      title: 'Basic Resume Analysis',
      description:
        'Get a basic analysis of your one free resume with our resume analyzer',
    },
    {
      title: 'Basic LinkedIn Optimizer',
      description:
        'Update your LinkedIn profile following our guidelines and best practices',
    },
    {
      title: 'Job Application Tracker',
      description:
        'Organize applications, track progress, and stay on top of opportunities.',
    },
    {
      title: 'Networking Tracker',
      description:
        'Find and connect with recruiters and hiring managers to boost your job search reach.',
    },
    {
      title: 'Recruiter Search',
      description:
        'Find and connect with recruiters and hiring managers to boost your job search reach.',
    },
    {
      title: 'Job Application Autofill',
      description:
        'Autofill applications with saved details for quick and accurate submissions.',
    },

    {
      title: 'Resources and Guides',
      description:
        'Get expert resources and guides to support every step of your job search',
    },

    {
      title: 'Browser Extension',
      description:
        'Access Careerflow tools right in your browser to simplify applications and optimize your workflow.',
    },

    // {
    //   title: 'Networking Tracker for Building Connections',
    //   description:
    //     'Manage professional connections and track networking activities.',
    // },
    // {
    //   title: 'Secure Document Storage',
    //   description:
    //     'Store and access all job search documents in one secure place.',
    // },
    // {
    //   title: 'LinkedIn Profile Optimizer',
    //   description:
    //     'Optimize your LinkedIn profile to attract recruiters and improve visibility. (Basic)',
    // },
    // {
    //   title: 'Basic Support for Platform Assistance',
    //   description:
    //     'Get assistance with platform features and your job search journey. (Basic)',
    // },
    // {
    //   title: 'Resume Analysis for Improvement Suggestions',
    //   description:
    //     'Receive feedback on resume strengths and improvement areas. (Basic)',
    // },
    // {
    //   title: 'Skill Gap Analyzer to Identify Gaps',
    //   description:
    //     'Identify skill gaps and align with job requirements. (Score Only)',
    // },
  ],
  paidFeatures: [
    {
      title: 'Unlimited AI Resumes',
      description:
        'Create unlimited AI resumes with our most advanced AI resume builder',
    },
    {
      title: 'Unlimited AI Resume ATS Optimizer',
      description:
        'Tailor your Resume to specific job descriptions in one click!',
    },
    {
      title: 'Unlimited AI Resume Bullet and Summary Writer',
      description:
        'Get impactful resume bullet points to showcase achievements.',
    },
    {
      title: 'Advanced AI Resume Analysis',
      description:
        'Receive in-depth feedback on resume strengths and improvement areas.',
    },
    {
      title: 'Advanced AI LinkedIn Optimizer',
      description:
        'Optimize your LinkedIn profile to attract recruiters and improve visibility.',
    },
    {
      title: 'ATS Score and Keyword Analyzer',
      description:
        'Understand your ATS score for each job opportunity and get a detailed keyword analysis',
    },
    {
      title: 'Unlimited AI Cover Letter Writer',
      description:
        'Generate tailored cover letters for specific job applications in a single click with top cover letter templates',
    },
    {
      title: 'Unlimited AI Elevator Pitch Writer',
      description:
        'Craft a concise pitch to communicate your value effectively.',
    },
    {
      title: 'Unlimited AI LinkedIn Post Writer',
      description:
        'Create engaging LinkedIn posts to boost your profile activity.',
    },
    {
      title: 'Job Description Summarizer',
      description: 'Summarize job descriptions to quickly grasp key details.',
    },
    {
      title: 'Perks and Discounts on Services',
      description:
        'Get additional discounts on our other services and avail partner perks.',
    },
    {
      title: 'Premium Support',
      description:
        'Get enhanced assistance with platform features and your job search journey.',
    },
  ],
};

const SelectPlan = ({ setShowFeatures, setUpgrade }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [country, setCountry] = React.useState('US');
  const [viewMore, setViewMore] = React.useState(false);
  const { isMobile } = useContext(AppContext);
  const [searchParams] = useSearchParams();
  const isOnboarding = searchParams.get('onboarding');
  const basicRef = useRef<HTMLDivElement>(null);
  const [basicHeight, setBasicHeight] = useState(0);
  React.useEffect(() => {
    axios.get(config.baseUrl + '/faangpath/pricing').then((res: any) => {
      console.log(res);
      if (res.data.country === 'India') setCountry('INDIA');
    });
  }, []);

  useEffect(() => {
    analyticsEvent('Onboarding  - Pricing intro - viewed page', 'Onboarding', {
      first_timestamp: Date.now(),
    });
  }, []);

  useEffect(() => {
    setBasicHeight(basicRef.current?.clientHeight);
    window.addEventListener('resize', () => {
      setBasicHeight(basicRef.current?.clientHeight);
    });
    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  return (
    <FadeInComponent open={true}>
      <div className="plan">
        <div className={styles.paymentPageBg}></div>
        <div className={'features-root'}>
          <div className="plan-heading-1">{'Select a plan'}</div>
          <div className="plan-heading-2">{'Supercharge your job search'}</div>
          <div className="plan-heading-3">
            <div>{'Careerflow Premium members are'}</div>
            <div>
              {
                <span>
                  <span style={{ fontWeight: '700', position: 'relative' }}>
                    {' 10X more effective'}
                    <Strike className="strike" />
                  </span>
                  {' in their Job Search'}
                </span>
              }
            </div>
          </div>

          <Row className={'plan-container'}>
            <Col
              className={'feature-card'}
              lg={10}
              span={22}
              style={{ marginTop: '2.4rem', height: 'calc(100% - 2.4rem)' }}
            >
              <div className={clsx('basic-plan', 'free-plan')} ref={basicRef}>
                <div className="basic-plan-heading">
                  <div className="basic-heading-1">{'Free Plan'}</div>
                  <div className="basic-heading-2">{'$ 0'}</div>
                  <div className="basic-heading-3">
                    {'Forever, no hidden costs'}
                  </div>
                  <div className="basic-heading-4">
                    {'Best for passive job seekers who are not in a rush'}
                  </div>
                  <Button
                    size="large"
                    onClick={() => {
                      setShowFeatures(true);
                      localStorage.setItem('showFeatures', 'true');
                      navigate('/dashboard');
                      if (isOnboarding) {
                        analyticsEvent(
                          'Onboarding  - Pricing intro - Free CTA click',
                          'Onboarding'
                        );
                      }
                    }}
                    className="basic-heading-5"
                  >
                    {t('Start Free Plan')}
                  </Button>
                </div>
                <Divider style={{ margin: 0 }} />
                <div className="feature-item-container">
                  <Collapse ghost expandIcon={Bullet}>
                    {features.freeFeatures.map((feature, i) => (
                      <Panel
                        className="feature-item-collapse"
                        header={feature.title}
                        key={i + 1}
                      >
                        <p>{feature.description}</p>
                      </Panel>
                    ))}
                  </Collapse>
                </div>
              </div>
            </Col>
            <Col
              className={'feature-card'}
              lg={12}
              span={22}
              style={{
                color: ThemeColors.themeBackgroundColor,
                borderTop: '40px solid #FFD666',
                paddingTop: '0.75rem',
                position: 'relative',
              }}
            >
              <div
                style={{
                  marginTop: '-2.5rem',
                  minHeight: `${basicHeight + 28}px`,
                }}
              >
                <div
                  style={{
                    fontSize: '16px',
                    fontWeight: '500',
                    position: 'relative',
                    textAlign: 'center',
                    top: '-3px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                    justifyContent: 'center',
                  }}
                >
                  <Aitools />
                  <span>{'Careerflow Premium'}</span>
                </div>
                <div className="basic-plan">
                  <div className="basic-plan-heading">
                    <div className="basic-heading-1">
                      {'Plans starting from'}
                    </div>
                    <div className="basic-heading-2">
                      <span className="premium-heading">
                        {country === 'INDIA'
                          ? 'Rs. 250 / month'
                          : '$14 / month'}
                      </span>
                    </div>
                    <div className="basic-heading-3">
                      {'Forever, no hidden costs'}
                    </div>
                    <div className="basic-heading-4">
                      {
                        'Best for active job seekers who want to get hired up to 3X faster with the MOST ADVANCED job search suite on the planet!'
                      }
                    </div>
                    <Button
                      type="primary"
                      size="large"
                      onClick={() => {
                        setUpgrade(true);
                        if (isOnboarding) {
                          analyticsEvent(
                            'Onboarding  - Pricing intro - Premium CTA click',
                            'Onboarding'
                          );
                        }
                      }}
                      className="basic-heading-5-premium"
                    >
                      Upgrade to Premium
                    </Button>
                  </div>
                </div>

                <Divider style={{ margin: 0 }} />
                <div className="feature-item-container-premium">
                  <div className="premium-feature-heading">
                    {'Everything in Free Plan; Plus'}
                  </div>
                  <div className="feature-item-container-inner">
                    <Collapse ghost expandIcon={Bullet}>
                      {features.paidFeatures
                        .filter((_item, index) =>
                          isMobile
                            ? true
                            : !viewMore && index > 3
                              ? false
                              : true
                        )
                        .map((feature, i) => (
                          <Panel
                            className="feature-item-collapse"
                            header={feature.title}
                            key={i + 1}
                          >
                            <p>{feature.description}</p>
                          </Panel>
                        ))}
                    </Collapse>
                  </div>

                  {!isMobile && (
                    <Fragment>
                      {!viewMore ? (
                        <Button
                          style={{
                            margin: 0,
                            padding: 0,
                            textAlign: 'left',
                            marginTop: '1rem',
                          }}
                          type="link"
                          onClick={() => {
                            setViewMore(true);
                            if (isOnboarding) {
                              analyticsEvent(
                                'Onboarding  - Pricing intro - view more',
                                'Onboarding'
                              );
                            }
                          }}
                        >
                          {'View more'}
                        </Button>
                      ) : (
                        <Button
                          style={{
                            margin: 0,
                            padding: 0,
                            textAlign: 'left',
                            marginTop: '1rem',
                          }}
                          type="link"
                          onClick={() => setViewMore(false)}
                        >
                          {'View less'}
                        </Button>
                      )}
                    </Fragment>
                  )}

                  <div className="premium-footer">
                    <img
                      style={{ height: '40px' }}
                      src={People}
                      alt="job-seekers"
                    ></img>
                    <div className="premium-footer-text">
                      <span>{'Trusted By Over'}</span>
                      <span style={{ fontWeight: '800' }}>{' 500K'}</span>
                      <span style={{ color: '#1570EF' }}>
                        {' Job Seekers!'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </FadeInComponent>
  );
};

export default SelectPlan;
